
  .button2 {
    padding: 0.2rem 0.1rem;
    border-radius: 10px;
    border: none;
    background-color: var(--secondary-color);
    text-align: center;
    font-weight: 500;
    color: var(--white-color);
    outline: none;
    margin: 1rem 0 0 0;
    font-family: var(--font-base);

    transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    cursor: pointer;

    &:hover {
      background-color: #2430af;
    }
  }



  @media only screen and (max-width: 768px) {
  .custom-image {
    max-width: 60%; /* Set maximum width to 100% */
    height: auto; /* Ensure the aspect ratio is maintained */
  }
}

@media only screen and (max-width: 486px) {
  .custom-image {
    max-width: 60%; /* Set maximum width to 100% */
    height: auto; /* Ensure the aspect ratio is maintained */
  }
}
 
  .app__header2 {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;

    padding: 6rem 2rem 0;

    @media screen and (min-width: 2000px) {
        padding-top: 8rem;
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }

    @media screen and (max-width: 450px) {
        padding: 6rem 1rem 2rem;
    }
}

 

