.app__about{
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__profiles{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
  text-decoration: none
}

.app__profiles-item{
  width: 190px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem;

  img{
      width: 100%;
      height: 170px;
      border-radius: 15px;
      object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
      width: 370px;
      margin: 2rem 4rem;

      img{
          height: 320px;
      }
  }
}

.no-link-effect {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Use the default text color */
  cursor: pointer; /* Use the default cursor (usually an arrow) */
}

.bold-text {
  font-weight: bold;
}

.items-center {
  display: flex;
  align-items: center;
}


.app__profiles-item {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
}

.text-container {
  flex: 1; /* Take up remaining vertical space */
  display: flex;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
}

.bold-text {
  font-weight: bold;
  margin-top: 20px;
}
