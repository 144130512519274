.qna-container {
    margin: 10px;
    padding: 20px;
    border-radius: 5px; /* Added border-radius for rounded corners */
    cursor: pointer;
  
    .qna-question {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .qna-chevron {
        font-size: 20px;
        transition: transform 0.3s ease-in-out;
        color: #34429B;
      }
    }
  
    .qna-answer {
      display: none;
  
      &.active {
        display: block;
      }
    }
  }


  .qna-chevron {
    font-size: 20px;
    transition: transform 0.3s ease-in-out;
  
    &.open {
      transform: rotate(360deg);
    }
  
    &.closed {
      transform: rotate(0deg);
    }
  }
  
  .qna-container .qna-row .qna-pair {
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 5px; /* Added border-radius for rounded corners */
    padding: 10px;
    cursor: pointer;
  
    .qna-question {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .qna-chevron {
        font-size: 20px;
        transition: transform 0.3s ease-in-out;
      }
    }
  
    .qna-answer {
      display: none;
  
      &.active {
        display: block;
      }
    }
  }
  

  .bold-text {
    font-weight: bold;
  }

  .centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust as needed */
  }

  .centered-container2 {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh; /* Adjust as needed */

  }

  .centered-container3 {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh; /* Adjust as needed */

  }
  
  .item-center {
    text-align: center;
    margin-bottom: 40px; /* Adjust this value as needed */

  }
  


  .profile-image {
    width: 200px;
    height: 300px;
  }

  .bold-text {
    font-weight: bold;
  }
