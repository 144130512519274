.blog {
    font-weight: bold;
    z-index: 2;
    font-size: 2.25rem; // Equivalent to text-4xl
    margin-top: 1.25rem; // Equivalent to mt-5
    margin-bottom: 2.5rem; // Equivalent to mb-10
    letter-spacing: 0.125em; // Equivalent to tracking-widest
    text-align: center;
    @media (min-width: 768px) {
      font-size: 3rem; // Equivalent to md:text-6xl
    }
    @media (min-width: 1024px) {
      font-size: 4rem; // Equivalent to lg:text-8xl
    }
  }
  
  .blog2 {
    font-weight: normal;
    z-index: 1;
    font-size: 1.25rem; // Equivalent to text-4xl
    margin-top: 1.25rem; // Equivalent to mt-5
    margin-bottom: 2.5rem; // Equivalent to mb-10
    letter-spacing: 0.125em; // Equivalent to tracking-widest
    text-align: center;
    @media (min-width: 768px) {
      font-size: 2rem; // Equivalent to md:text-6xl
    }
    @media (min-width: 1024px) {
      font-size: 1.5rem; // Equivalent to lg:text-8xl
    }
  }
  
  
  
  .body-padding {
    padding-left: 2.5rem; // Equivalent to px-10
    padding-right: 2.5rem; // Equivalent to px-10
    
    @media (min-width: 1280px) {
      max-width: 90rem; // Equivalent to 2xl:max-w-7xl
      margin-left: auto; // Equivalent to 2xl:mx-auto
      margin-right: auto; // Equivalent to 2xl:mx-auto
    }
  }
  
  .rozdelenie-blogu {
   
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 1.25rem; // You can adjust the value as needed
      
        @media (min-width: 768px) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      
        @media (min-width: 1024px) {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }
  
      
  }
  
  .title {
    .text-xl-mt-2 {
      font-size: 1.25rem; /* This corresponds to the "text-xl" class */
      margin-top: 2rem;
    }
    margin-top: 2rem; // Apply the margin-top
  }
  
  .smaller-square-image {
    width: 420px; /* Adjust the width as needed */
    height: 300px; /* This ensures a square aspect ratio */
    object-fit:inherit;
    justify-content: center;
    margin-left: 30px;
    border-radius: 30px; /* Add smooth borders with a radius of 10px */
  
  }
  .smaller-square-image2 {
    width: 500px; /* Adjust the width as needed */
    height: 510px; /* This ensures a square aspect ratio */
    object-fit:cover; /* Maintain aspect ratio and crop if necessary */
    justify-content: center;
    margin-left: 30px;
    border-radius: 30px; /* Add smooth borders with a radius of 10px */
  
  }
  
  .post-container {
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    padding: 20px;
    width: 480px; /* Fixed width */
    min-height: 100px; /* Minimum height; adjust as needed */
  }
  
  .grids {
    display: grid;
    grid-template-columns: repeat(3, 1fr); // Default for large screens
    gap: 1.25rem; // Adjust the gap value as needed
  
    @media (max-width: 1541px) {
      grid-template-columns: repeat(2, 1fr); // For screens between 1024px and 1541px
    }
  
    @media (max-width: 1069px) {
      grid-template-columns: repeat(1, 1fr); // For screens up to 1069px (small screens)
    }
  }
  

    
  .no-link-effect {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Use the default text color */
    cursor: pointer; /* Use the default cursor (usually an arrow) */
  }

  .text {
    text-decoration:solid;/* Remove underline */
    font-weight: bold
  }