.app__navbar{
    width: 100%;
    display: flex;
    padding: 0.1rem 0.8rem; /* Adjusted padding */
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 3;
    
}


.mobile-view {
    margin-top: -10px;
  }


  @media (max-width: 445px) {
    .mobile-view {
      margin-top: -13px;
    }
  }

  .mobile-view2 {
    margin-top: -7px;
  }
  
  .mobile-view2 svg path {
    fill: black !important; /* Added !important to ensure priority */
  }
  
  @media (max-width: 445px) {
    .mobile-view2 {
      margin-top: -9px;
    }
  }
  
.logo {
    width: 526px; /* Adjust the size as needed */
    height: auto
    
  }

  
  @media (max-width: 768px) {
    .logo {
      width: 360px; /* Adjust the size for mobile devices */
    }

  }



  @media (max-width: 445px) {
    .logo {
      width: 230px; /* Adjust the size for mobile devices */
    }
  }





 .app__navbar-menu{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;


   
   

    .submenu-item {
        color: var(--gray-color);
        text-decoration: none;
        font-size: 0.9rem;
        font-weight: 300;

        transition: all 0.3s ease-in-out;

        &:hover{
            color: var(--secondary-color);

        }
      }

    svg{
        width: 70%;
        height:  70%;
        color: var(--white-color);
        cursor: pointer;
        
    }

    div{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 5;
        padding: 1rem;
        width: 35%;
        height: 100vh;
        display: left;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        background: url('../../assets/bgWhite.png');
        background-color: var(--white-color);
        background-size: cover;
        background-repeat: repeat;

        box-shadow: 0 0 20px rgba(10, 9, 9, 0.581);

        svg{
            width: 35px;
            height:  35px;
            color: var(--secondary-color);
            margin: 0.5rem 1rem;
            
              
        }

        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%; 
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            li{
                margin: 1rem;

                a{
                    color: var(--gray-color);
                    text-decoration: none;
                    font-size: 1rem;
                    text-transform: uppercase;
                    font-weight: 500;

                    transition: all 0.3s ease-in-out;

                    &:hover{
                        color: var(--secondary-color);

                    }
                }
            }
        }

       
    }

    @media screen and (max-width: 913px) {
        div {
            width: 45%;
        }
    }
    
    @media screen and (min-width: 914px) {
        div {
            width: 20%;
        }
    }
   
}

.app__navbar-links{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4;
    list-style: none;
    

    li{
        margin: 0 1rem;
        cursor: pointer;
        flex-direction: column;

        div{
            width: 5px;
            height: 5px;
            border-radius: 50%;

            margin-bottom: 5px;
        }

        a{
            color: var(--gray-color);
            text-decoration: none;
            flex-direction: column;
            text-transform: uppercase;
            font-weight: 500;

            transition: all 0.3s ease-in-out;

            &:hover{
                color: var(--secondary-color);

            }
        }

        &:hover{
            div{
                background-color: var(--secondary-color);
            }
        }
    }



   
}

.app__navbar-logo{
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img{
        width: 90px;
        height: 20px;

        @media screen and (min-width: 2000px) {
            width: 180px;
            height: 40px;
        }
    }
}



.sub-submenu-item {
    color: var(--gray-color);
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: 300;

    transition: all 0.3s ease-in-out;

    &:hover{
        color: var(--secondary-color);

    }
  }
  

  



  



  



