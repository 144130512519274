.blog {
  font-weight: bold;
  z-index: 2;
  font-size: 2.25rem; // Equivalent to text-4xl
  margin-top: 1.25rem; // Equivalent to mt-5
  margin-bottom: 2.5rem; // Equivalent to mb-10
  letter-spacing: 0.125em; // Equivalent to tracking-widest
  text-align: center;
  @media (min-width: 768px) {
    font-size: 3rem; // Equivalent to md:text-6xl
  }
  @media (min-width: 1024px) {
    font-size: 4rem; // Equivalent to lg:text-8xl
  }
}

.blog2 {
  font-weight: normal;
  z-index: 1;
  font-size: 1.25rem; // Equivalent to text-4xl
  margin-top: 1.25rem; // Equivalent to mt-5
  margin-bottom: 2.5rem; // Equivalent to mb-10
  letter-spacing: 0.125em; // Equivalent to tracking-widest
  text-align: center;
  @media (min-width: 768px) {
    font-size: 2rem; // Equivalent to md:text-6xl
  }
  @media (min-width: 1024px) {
    font-size: 1.5rem; // Equivalent to lg:text-8xl
  }
}



.body-padding {
  padding-left: 2.5rem; // Equivalent to px-10
  padding-right: 2.5rem; // Equivalent to px-10
  
  @media (min-width: 1280px) {
    max-width: 90rem; // Equivalent to 2xl:max-w-7xl
    margin-left: auto; // Equivalent to 2xl:mx-auto
    margin-right: auto; // Equivalent to 2xl:mx-auto
  }
}

.rozdelenie-blogu {
 
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 1.25rem; // You can adjust the value as needed
    
      @media (min-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    
      @media (min-width: 1024px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

    
}

.title {
  .text-xl-mt-2 {
    font-size: 1.25rem; /* This corresponds to the "text-xl" class */
    margin-top: 2rem;
  }
  margin-top: 2rem; // Apply the margin-top
}

.smaller-square-image {
  width: 420px; /* Adjust the width as needed */
  height: 300px; /* This ensures a square aspect ratio */
  object-fit:inherit;
  justify-content: center;
  margin-left: 30px;
  border-radius: 30px; /* Add smooth borders with a radius of 10px */

}
.smaller-square-image2 {
  width: 500px; /* Adjust the width as needed */
  height: 510px; /* This ensures a square aspect ratio */
  object-fit:cover; /* Maintain aspect ratio and crop if necessary */
  justify-content: center;
  margin-left: 30px;
  border-radius: 30px; /* Add smooth borders with a radius of 10px */

}

.post-container {
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  padding: 20px;
  width: 280px; /* Fixed width for larger screens */
  min-height: 100px; /* Minimum height; adjust as needed */

  @media (max-width: 468px) {
    padding: -30px; /* Reduce padding for mobile */
    width: calc(100% - 50px); /* Adjust width for mobile devices */
    /* 
    * Using `calc(100% - 40px)` to consider the padding and make sure 
    * it fits within the screen properly
    */
  }
}

.grids {
  display: grid;
  grid-template-columns: repeat(3, 1fr); // Default for large screens
  gap: 1.25rem; // Adjust the gap value as needed
  

  @media (max-width: 1541px) {
    grid-template-columns: repeat(2, 1fr); // For screens between 1024px and 1541px
    width: 50%; /* Set width to 50% */

  }

  

  @media (max-width: 1069px) {
    grid-template-columns: repeat(1, 1fr); // For screens up to 1069px (small screens)
    width: 50%; /* Set width to 50% */

  }
}


.square-post-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Adjust min width and fractions as needed */
  gap: 1.25rem; /* Adjust the gap value as needed */
}

@media (max-width: 1147px ) {
  .square-post-container {
    grid-template-columns: repeat(1, 1fr); /* For mobile, display one post per row */
    width: 50%; /* Set width to 50% */

  }
}


.post-content {
  padding: 10px; /* Adjust padding as needed */
}

.square-post-container {
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr)); /* Adjust min width and fractions as needed */
}

@media (max-width: 768px) {
  .post-content {
    padding: -50px; /* Reduce padding for tablet */
  }

  .smaller-square-image {
    width: 200px; /* Adjust image width for tablet */
    height: 150px; /* Adjust image height for tablet */
  }

  .smaller-square-image2 {
    width: 250px; /* Adjust image width for tablet */
    height: 250px; /* Adjust image height for tablet */
  }
}

@media (max-width: 1024px) {
  .square-post-container {
    grid-template-columns: repeat(1, 1fr); /* For tablet, display one post per row */
  }
}



.app__profiles1 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
  text-decoration: none;
}

.app__profiles1 > * {
  margin-right: 1rem; /* Adjust the value to set the horizontal gap */
  margin-bottom: 1rem; /* Adjust the value to set the vertical gap */
}

/* To remove the margin from the last item in each row, you can use the nth-child selector */
.app__profiles1 > *:nth-child(3n) {
  margin-right: 0; /* Adjust this to remove the right margin from the last item in each row */
}
