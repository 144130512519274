#home{
    position: relative;
    background-size: cover;
    background-repeat: repeat;
    background-position: center;

    .app__wrapper{
        padding: 0;

        .copyright{
            display: none;
        }
    }
}

.app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;

    padding: 6rem 2rem 0;

    @media screen and (min-width: 2000px) {
        padding-top: 8rem;
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }

    @media screen and (max-width: 450px) {
        padding: 6rem 1rem 2rem;
    }
}

.app__header2 {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;
    background-color: white;
    padding: 6rem 2rem 0;

    @media screen and (min-width: 2000px) {
        padding-top: 8rem;
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }

    @media screen and (max-width: 450px) {
        padding: 6rem 1rem 2rem;
    }
}



.app__header video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  bottom: 20px;
  object-fit: cover;
  z-index: 1;
}


.app__header-badge{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    .badge-cmp .tag-cmp{
        padding: 1rem 2rem;
        border:  var(--white-color);
        border-radius: 15px;
        flex-direction: row;
        width: auto;


        box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    }

    .tag-cmp{
        flex-direction: column;
        margin-top: 3rem;

        p{
            width: 100%;
            text-transform: uppercase;
            text-align: right;
        }
    }

    span{
        font-size: 2.5rem;

        @media screen and (min-width: 2000px) {
            font-size: 5rem;
        }
    }

    @media screen and (max-width: 1200px) {
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.app__header-circles{
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;

    div{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: var(--white-color);

        box-shadow: 0px 0px 20px rgba(0,0,0,0.1);

        img{
            width: 60%;
            height: 60%;
        }
    }

    div:nth-child(1){
        width: 100px;
        height: 100px;
    }

    div:nth-child(2){
        margin: 1.75rem;
        width: 150px;
        height: 150px;
    }

    div:nth-child(3){
        width: 70px;
        height: 70px;
    }

    @media screen and (min-width: 2000px) {
        div:nth-child(2){
            width: 400px;
            height: 400px;
        }
    
        div:nth-child(3){
            width: 170px;
            height: 170px;
        }
    
        div:nth-child(1){
            width: 200px;
            height: 200px;
        }
    }

    @media screen and (max-width: 1200px) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;

        div{
            margin: 1rem;
        }
    }

}

.app__header-img{
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
    

    video {
        width: 100%;
        object-fit: contain;
        z-index: -1;
    }

    .overlay_circle{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: 100%;
        height: 90%;
    }

    @media screen and (max-width: 1200px) {
        margin: 2rem 0;
    }
}



.app__testimonial {
    flex: 1;
    width: 100%;
    flex-direction: column;
}
  
.app__testimonial-item {
    width: 60%;
    min-height: 320px;
    background-color: var(--white-color);
    display: flex;
    flex-direction: row;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  
    transition: all 0.3s ease-in-out;
  
    img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
    }
  
    @media screen and (min-width: 2000px) {
        min-height: 450px;
    
        img {
            width: 150px;
            height: 150px;
        }
    }
  
    @media screen and (max-width: 850px) {
        width: 100%;
    }
  
    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
}
  
.app__testimonial-content {
    flex: 1;
    height: 100%;
    padding: 0 2rem;
    text-align: left;
  
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  
    p {
        font-size: 1.25rem;
        line-height: 2rem;
        color: var(--black-color);
        font-family: var(--font-base);
    
        @media screen and (min-width: 2000px) {
            font-size: 2rem;
            line-height: 3.5rem;
        }
    }
  
    h4 {
        font-weight: 600;
        color: var(--secondary-color);
        margin-top: 2rem;
    }
  
    h5 {
        font-weight: 400;
        color: var(--gray-color);
        margin-top: 5px;
    }
  
    @media screen and (max-width: 600px) {
        margin-top: 2rem;
        padding: 0;
    }
}
  
.app__testimonial-btns {
    flex-direction: row;
    margin-top: 1rem;
  
    div {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: var(--white-color);
    
        margin: 1rem;
        transition: all 0.3s ease-in-out;
    
        svg {
            width: 20px;
            height: 20px;
            color: var(--secondary-color);
        }
    
        &:hover {
            background-color: var(--secondary-color);
    
            svg {
            color: var(--white-color);
            }
        }
    
        @media screen and (min-width: 2000px) {
            width: 100px;
            height: 100px;
    
            svg {
            width: 45px;
            height: 45px;
            }
        }
    }
}
  


.app__testimonial-item3 {
    width: 60%;
    min-height: 320px;
    background-color: var(--white-color);
    display: flex;
    flex-direction: row;
    justify-content: flex-end; /* Align items to the right side */
    align-items: flex-end; /* Align items to the bottom */
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    background: rgba(232, 230, 230, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    transition: all 0.3s ease-in-out;
    position: absolute; /* Change to absolute positioning */
    bottom: 20px; /* Adjust the bottom position as needed */
    right: 20px; /* Adjust the right position as needed */
    z-index: 2; /* Add a higher z-index to make it appear above other elements */

  
    img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
    }
  
    @media screen and (min-width: 2000px) {
        min-height: 450px;
    
        img {
            width: 150px;
            height: 150px;
        }
    }
  
    @media screen and (max-width: 850px) {
        width: 70%;
    }
  
    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
}
  
.app__testimonial-content {
    flex: 1;
    height: 100%;
    padding: 0 2rem;
    text-align: left;
  
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  
    p {
        font-size: 1.25rem;
        line-height: 2rem;
        color: var(--black-color);
        font-family: var(--font-base);
    
        @media screen and (min-width: 2000px) {
            font-size: 2rem;
            line-height: 3.5rem;
        }
    }
  
    h4 {
        font-weight: 600;
        color: var(--secondary-color);
        margin-top: 2rem;
    }
  
    h5 {
        font-weight: 400;
        color: var(--gray-color);
        margin-top: 5px;
    }
  
    @media screen and (max-width: 600px) {
        margin-top: 2rem;
        padding: 0;
    }
}
  
.app__testimonial-btns {
    flex-direction: row;
    margin-top: 1rem;
  
    div {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: var(--white-color);
    
        margin: 1rem;
        transition: all 0.3s ease-in-out;
    
        svg {
            width: 20px;
            height: 20px;
            color: var(--secondary-color);
        }
    
        &:hover {
            background-color: var(--secondary-color);
    
            svg {
            color: var(--white-color);
            }
        }
    
        @media screen and (min-width: 2000px) {
            width: 100px;
            height: 100px;
    
            svg {
            width: 45px;
            height: 45px;
            }
        }
    }
}


.app__testimonial-btns2 {
    flex-direction: row;
    margin-top: 1rem;
    z-index: 2;
    position: fixed; /* Use fixed positioning to not affect the layout */
    width: 60%;
    min-height: 320px;
    bottom: 20px; /* Adjust as needed */
    right: -1000px; /* Adjust as needed */
    z-index: 2;
    display: flex;
    transition: all 0.3s ease-in-out;

    div {
        width: 250px; /* Increase the width */
        height: 250px; /* Increase the height */
        border-radius: 50%;
        background-color: var(--white-color);
        margin: 1rem;
        transition: all 0.3s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center; /* Center the content vertically and horizontally */

        svg {
            width: 100px; /* Adjust the SVG size as needed */
            height: 100px; /* Adjust the SVG size as needed */
            color: var(--secondary-color);
        }

        &:hover {
            background-color: var(--secondary-color);

            svg {
                color: var(--white-color);
            }
        }

        @media screen and (min-width: 2000px) {
            width: 150px; /* Increase the width for larger screens */
            height: 150px; /* Increase the height for larger screens */

            svg {
                width: 60px; /* Increase the SVG width for larger screens */
                height: 60px; /* Increase the SVG height for larger screens */
            }
        }
    }
}


.app__testimonial-item4 {
    width: 60%;
    min-height: 520px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end; /* Align items to the right side */
    align-items: flex-end; /* Align items to the bottom */
    padding: 2rem;
  
 position: absolute; /* Change to absolute positioning */
  bottom: 20px; /* Adjust the bottom position as needed */
  right: 0; /* Set right to 0 to align with the right edge */
  z-index: 2; /* Add a higher z-index to make it appear above other elements */
  
    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
    }
  
    @media screen and (min-width: 2000px) {
      min-height: 450px;
  
      img {
        width: 150px;
        height: 150px;
      }
    }
  
    @media screen and (max-width: 850px) {
      width: 70%;
    }
  
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
  }


#single2 {
  position: relative;
}

.scroll-arrow-container {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.scroll-arrow-container span {
  position: absolute;
  height: 4px;
  width: 30px;
  background-color: #ffffff;
  z-index: 2;
}

.scroll-arrow-container span.arrow-left {
  transform: rotate(30deg);
  left: -17px;
}

.scroll-arrow-container span.arrow-right {
  transform: rotate(-30deg);
  right: -17px;
}

@keyframes single2 {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(20px);
    opacity: 0;
  }
}

#single2 .scroll-arrow-container {
  animation-name: single2;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}


.app__social2 {
  z-index: 4;

}
  
